import { useRef, useState } from 'react';

export default function usePublishBot() {
  const apiUrlWs = process.env.REACT_APP_URL_WS;
  const [publishing, setPublishing] = useState(true);
  const [progress, setProgress] = useState(0);
  const [publishingFinished, setPublishingFinished] = useState(false);
  const [wsError, setWsError] = useState(false);
  const [wsErrorMessage, setWsErrorMessage] = useState('');
  const [semaphoreId, setSemaphoreId] = useState('');
  const [connection, setConnection] = useState<WebSocket>();

  const lastMessageTimeRef = useRef<number>(Date.now());
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  function publishBot(botDrawflow: any, botName: string) {
    if (botDrawflow && botName) {
      const action = `bots/publish`;

      const refreshToken = localStorage.getItem('refresh_token');
      const token = localStorage.getItem('token');

      if (!!token && !!refreshToken) {
        const data = { token: token, bot_drawflow: botDrawflow };
        const wsConnection = new WebSocket(apiUrlWs + action);

        wsConnection.onopen = () => {
          wsConnection.send(JSON.stringify(data));
          setConnection(wsConnection);
        };

        wsConnection.onerror = () => {
          setWsError(true);
          wsConnection.close();
        };

        wsConnection.onmessage = (e) => {
          const response = JSON.parse(e.data);
          const percentageResponse = response.publishing_percentage;

          lastMessageTimeRef.current = Date.now();

          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }

          if (response.type === 'error') {
            setWsErrorMessage(response.message);
          }

          if (response.type === 'notification') {
            setSemaphoreId(response.semaphore);
          }

          if (percentageResponse) {
            setProgress(percentageResponse);
          }

          if (percentageResponse >= 100) {
            setWsErrorMessage('Bot publicado com sucesso!');
            wsConnection.close();
          }
        };

        wsConnection.onclose = () => {
          handlePublish(false);

          if (wsError) {
            setPublishingFinished(false);
          } else setPublishingFinished(true);
        };

        const checkTimeout = () => {
          const currentTime = Date.now();
          
          if (currentTime - lastMessageTimeRef.current >= 10000) {
            setWsErrorMessage('A publicação demorou mais do que o esperado.');
            setWsError(true);
            wsConnection.close();
          } else {
            timeoutIdRef.current = setTimeout(checkTimeout, 1000);
          }
        };

        if (progress >= 90) {
          checkTimeout();
        }
      } else {
        handlePublish(false);
        setPublishingFinished(false);
      }
    }
  }

  function getConnectionStatus() {
    return connection?.readyState;
  }

  function handlePublish(status: boolean) {
    setPublishing(status);
  }

  function getProgress() {
    return progress;
  }

  function isPublishing() {
    return publishing;
  }

  function getMessage() {
    return wsErrorMessage;
  }

  function getSemaphoreId() {
    return semaphoreId;
  }

  function hasPublishingFinished() {
    return publishingFinished;
  }

  return {
    getConnectionStatus,
    isPublishing,
    getProgress,
    publishBot,
    handlePublish,
    hasPublishingFinished,
    getMessage,
    getSemaphoreId,
  };
}
